<template>
  <div class="step-4">
    <ul class="report-team-info info">
      <li><span>队伍名称：</span>{{ team.teamName }}</li>
      <li><span>领队：</span>{{ team.leaderName }}</li>
      <li><span>领队手机：</span>{{ team.leaderPhone }}</li>
      <div class="clear" />
    </ul>
    <ul class="report-team-info info">
      <li v-if="team.coachName">
        <span>套路项目教练：</span>{{ team.coachName }}
      </li>
      <li v-if="team.coachPhone">
        <span>教练手机：</span>{{ team.coachPhone }}
      </li>

      <li v-if="team.coachName2">
        <span>推手项目教练：</span>{{ team.coachName2 }}
      </li>
      <li v-if="team.coachPhone2">
        <span>教练手机：</span>{{ team.coachPhone2 }}
      </li>
      <div class="clear" />
    </ul>

    <div class="report-item">
      <div class="report-item__header">个人项目（共{{ person.length }}个）</div>
      <el-table
        class="report-item__body"
        :data="person"
        max-height="400px"
        border
        fit
        style="width: 100%;"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="姓名" width="100"></el-table-column>
        <el-table-column
          prop="cardId"
          label="证件号码"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="birth"
          label="出生年月"
          width="110"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="60"></el-table-column>
        <el-table-column
          prop="group"
          label="组别"
          width="115"
        ></el-table-column>
        <el-table-column
          prop="itemName"
          label="项目名称"
          min-width="280"
        ></el-table-column>
        <el-table-column prop="cost" label="费用"></el-table-column>
      </el-table>
      <div class="cost-box">合计：{{ personalCost }} 元</div>
    </div>

    <div class="report-item">
      <div class="report-item__header">对练项目（共{{ duelT.length }}个）</div>
      <el-table
        class="report-item__body"
        :data="duel"
        max-height="400px"
        border
        fit
        style="width: 100%;"
      >
        <el-table-column prop="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="姓名" width="100"></el-table-column>
        <el-table-column
          prop="cardId"
          label="证件号码"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="birth"
          label="出生年月"
          width="110"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="60"></el-table-column>
        <!-- <el-table-column prop="group" label="组别" width="120"></el-table-column> -->
        <el-table-column
          prop="itemName"
          label="项目名称"
          min-width="280"
        ></el-table-column>
        <el-table-column prop="cost" label="费用"></el-table-column>
      </el-table>
      <div class="cost-box">合计：{{ duelCost }} 元</div>
    </div>

    <div class="report-item">
      <div class="report-item__header">
        集体项目（共{{ collectiveT.length }}个）
      </div>
      <el-table
        class="report-item__body"
        :data="collective"
        max-height="400px"
        border
        fit
        style="width: 100%;"
      >
        <el-table-column prop="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="姓名" width="100"></el-table-column>
        <el-table-column
          prop="cardId"
          label="证件号码"
          min-width="180"
        ></el-table-column>
        <!-- <el-table-column prop="birth" label="出生年月" width="110"></el-table-column> -->
        <!-- <el-table-column prop="sex" label="性别" width="60"></el-table-column> -->
        <el-table-column
          prop="group"
          label="组别"
          width="115"
        ></el-table-column>
        <el-table-column
          prop="itemName"
          label="项目名称"
          min-width="280"
        ></el-table-column>
        <el-table-column prop="cost" label="费用">60</el-table-column>
      </el-table>
      <div class="cost-box">合计：{{ collectiveCost }} 元</div>
    </div>

    <div class="report-item">
      <div class="report-item__header">
        对抗项目（共{{ kickboxingT.length }}个）
      </div>
      <el-table
        class="report-item__body"
        :data="kickboxing"
        max-height="400px"
        border
        fit
        style="width: 100%;"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="姓名" width="90"></el-table-column>
        <el-table-column
          prop="cardId"
          label="证件号码"
          width="180"
        ></el-table-column>
        <!-- <el-table-column prop="birth" label="出生年月" width="110"></el-table-column> -->
        <el-table-column prop="sex" label="性别" width="55"></el-table-column>
        <!-- <el-table-column prop="height" label="身高" width="60"></el-table-column> -->
        <el-table-column prop="weight" label="体重级别"></el-table-column>
        <el-table-column prop="tuishouGroup" label="组别"></el-table-column>
        <!-- <el-table-column prop="learningCycle" label="学习周期" width="80"></el-table-column> -->
        <el-table-column prop="cost" label="费用"></el-table-column>
      </el-table>
      <div class="cost-box">合计：{{ kickboxingCost }} 元</div>
    </div>

    <div class="report-item">
      <el-table
        class="report-item__body"
        :data="aggregateCost"
        border
        fit
        style="width: 100%;"
      >
        <el-table-column prop="item" label="项目" width="100"></el-table-column>
        <el-table-column
          prop="personal"
          label="个人项目"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="duel"
          label="对练项目"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="collective"
          label="集体项目"
          min-width="100"
        ></el-table-column>
        <!-- <el-table-column prop="people" label="运动员人数" min-width="100"></el-table-column> -->
        <el-table-column
          prop="kickboxing"
          label="太极推手项目"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="cost"
          label="合计（元）"
          width="385"
        ></el-table-column>
      </el-table>
    </div>

    <div class="step-btn-group">
      <el-button type="primary" @click="prevStep()">上一步</el-button>
      <el-button type="primary" @click="nextStep()">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { getAll, getAllAdmin } from "@/api";
export default {
  name: "Step4",
  components: {},
  props: {},
  data() {
    return {
      team: {},
      personT: [],
      duelT: [],
      collectiveT: [],
      kickboxingT: [],
      peopleSum: 0 // 运动员人数
    };
  },
  computed: {
    person() {
      let arr = [];
      this.personT.forEach(item => {
        if (item.boxing) {
          arr.push({
            ...item,
            itemName: `${item.boxing}${item.boxingRoutine ? "-" : ""}${
              item.boxingRoutine
            }`,
            cost: 100
          });
        }
        if (item.instrument) {
          arr.push({
            ...item,
            itemName: `${item.instrument}${item.instrumentRoutine ? "-" : ""}${
              item.instrumentRoutine
            }`,
            cost: 100
          });
        }
      });
      return arr;
    },
    duel() {
      // return this.$store.state.project.duel
      let arr = [];
      this.duelT.forEach((item, index) => {
        item.contestants.forEach((contestant, contestantsIndex) => {
          arr.push({
            index: `${index + 1}-${contestantsIndex + 1}`,
            ...contestant,
            itemType: item.itemType,
            itemRoutine: item.itemRoutine,
            itemName: `${item.item} - ${item.itemRoutine}`,
            cost: 100
          });
        });
      });
      return arr;
    },
    // 集体
    collective() {
      // return this.$store.state.project.collective
      let arr = [];
      this.collectiveT.forEach((item, index) => {
        item.contestants.forEach((contestant, contestantsIndex) => {
          arr.push({
            index: `${index + 1}-${contestantsIndex + 1}`,
            ...contestant,
            itemType: item.itemType,
            itemRoutine: item.itemRoutine,
            itemName:
              item.item + (`${item.itemRoutine}` ? `-${item.itemRoutine}` : ""),
            cost: 60
          });
        });
      });
      return arr;
    },
    kickboxing() {
      // return this.$store.state.project.kickboxing
      let arr = [];
      this.kickboxingT.forEach(item => {
        arr.push({
          ...item,
          cost: 100
        });
      });
      return arr;
    },

    personalCost() {
      let cost = 0;
      this.person.forEach(element => {
        cost += 100;
      });
      return cost;
    },
    duelCost() {
      let cost = 0;
      this.duel.forEach(element => {
        cost += 100;
      });
      return cost;
    },
    collectiveCost() {
      let cost = 0;
      this.collective.forEach(element => {
        cost += 60;
      });
      return cost;
    },
    kickboxingCost() {
      let cost = 0;
      this.kickboxing.forEach(element => {
        cost += 100;
      });
      return cost;
    },
    // 汇总费用
    aggregateCost() {
      return [
        {
          item: "费用",
          personal: this.personalCost,
          duel: this.duelCost,
          collective: this.collectiveCost,
          kickboxing: this.kickboxingCost,
          // cost: this.peopleSum * 20, // 按人数收费
          cost:
            this.personalCost +
            this.duelCost +
            this.collectiveCost +
            this.kickboxingCost, // 按项目收费
          people: this.peopleSum
        }
      ];
    }
  },
  watch: {},
  created() {
    if (this.$store.state.user.authority === 0) {
      // 用户
      getAll()
        .then(res => {
          this.$store.commit("SET_STATUS", res.data.status);
          this.peopleSum = res.data.peopleSum;
          this.team = res.data.team;
          this.personT = res.data.person;
          this.duelT = res.data.duel;
          this.collectiveT = res.data.collective;
          this.kickboxingT = res.data.kickboxing;
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      // 管理员
      getAllAdmin()
        .then(res => {
          this.$store.commit("SET_STATUS", res.data.status);
          this.peopleSum = res.data.peopleSum;
          this.team = res.data.team;
          this.personT = res.data.person;
          this.duelT = res.data.duel;
          this.collectiveT = res.data.collective;
          this.kickboxingT = res.data.kickboxing;
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  mounted() {},
  methods: {
    prevStep() {
      this.$store.dispatch("STEP_PREV", {
        router: this.$router,
        route: this.$route
      });
    },
    nextStep() {
      this.$store.dispatch("STEP_NEXT", {
        router: this.$router,
        route: this.$route
      });
    }
  }
};
</script>

<style scoped lang="scss">
.info li {
  float: left;
  margin: 5px 15px 5px 0;
  color: #409eff;
}

.info span {
  color: #000;
}
</style>
